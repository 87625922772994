
import { useModal, UseModal } from '@/composable/useModal'
import { useProfile } from '@/composable/useProfile'
import { exportExcel, ExportParams } from '@/services/export-excel'
import QTable from 'quasar/src/components/table/QTable.js';
import {
  computed,
  defineComponent,
  inject,
  PropType,
  provide,
  ref,
  watch,
} from 'vue'
import DeviationLogModal from '@/components/deviation/DeviationLogModal.vue'
import { getLog } from '@/api/log/getLog'
import { searchDelay } from '@/api/search/searchDelay'
import { v4 as uuidv4 } from 'uuid'
import {
  DeviationDelayModel,
  useDeviationDelay,
} from '@/composable/useDeviationDelay'
import { useDeviationCancel } from '@/composable/useDeviationCancel'
import { useDeviationVehicle } from '@/composable/useDeviationVehicle'
import { DeviationCancel } from '@/types/deviation-cancel'
import { DeviationDelay } from '@/types/deviation-delay'
import { DeviationVehicle } from '@/types/deviation-vehicle'
import { useDeviationDelayProposal } from '@/composable/useDeviationDelayProposal'
import { useDeviationCancelProposal } from '@/composable/useDeviationCancelProposal'
import { Event } from '@/types/event'
import { useDeviationVehicleProposal } from '@/composable/useDeviationVehicleProposal'
import { format } from 'date-fns'
import { routerPush } from '@/router'
import { useSearchTrain } from '@/composable/useSearchTrain'
import { useVehicleType } from '@/composable/useVehicleType'
import { searchTrainComposition } from '@/api/search/trainComposition'
import { orderBy } from 'lodash'
import { SearchTrainPlace } from '@/types/search-train-place'
import { getMpkEvent } from '@/api/mpk/getMpkEvent'
import DeviationDelayMPKModal from './deviation-delay/DeviationDelayMPKModal.vue'
import DeviationDelayModal from './DeviationDelayModal.vue'
import { searchPrevTripFromTraind } from '@/api/search/prevTripFromTraind'
import { updateEvent } from '@/api/event/updateEvent'
import { EventTrv } from '@/types/EventTrv'

interface DefaultInjectValue {
  openModal: () => boolean
}

export default defineComponent({
  name: 'DeviationTable',

  components: {
    DeviationLogModal,
    DeviationDelayMPKModal,
    DeviationDelayModal,
  },

  props: {
    deviationType: {
      type: String as PropType<
        | 'deviationVehicle'
        | 'deviationDelay'
        | 'deviationCrew'
        | 'deviationCancel'
        | 'deviationDisruption'
      >,
      required: true,
    },
    deviationProposalType: {
      type: String,
      default: () => 'none',
    },
    exportData: {
      type: Object as PropType<ExportParams>,
      required: true,
    },
    loading: Boolean,
    rows: Array as PropType<Record<string, unknown>[]>,
    columns: {
      type: Array as PropType<Required<QTable>['columns']>,
      required: true,
    },
    height: String,
    event: {
      type: Object as PropType<Event>,
      required: false,
    },
  },

  setup(props, { emit }) {
    const tableRef = ref<QTable>()
    const mpkModal = useModal()
    const delayModal = useModal()
    provide('delay-mpk-modal', mpkModal)
    provide('delay-modal-2', delayModal)

    const createDeviationFromProposalLoading = ref(false)
    const exporting = ref(false)
    const selectedItems = ref<Record<string, unknown>[]>([])
    const { can, projectDependentInputFields } = useProfile()

    const defaultInjectValue: DefaultInjectValue = {
      openModal: () => {
        return false
      },
    }

    const logModal = useModal()
    const { data: vehicleTypes } = useVehicleType()

    const { add: addDeviationDelay } = useDeviationDelay()
    const { data: deviationDelayProposals } = useDeviationDelayProposal()
    const { add: addDeviationCancel } = useDeviationCancel()
    const { data: deviationCancelProposals } = useDeviationCancelProposal()
    const { add: addDeviationVehicle } = useDeviationVehicle()
    const { data: deviationVehicleProposals } = useDeviationVehicleProposal()

    const { searchDirect } = useSearchTrain()

    provide('log-modal', logModal)

    const deleteModal = inject<
      UseModal<Record<string, unknown>> | DefaultInjectValue
    >('deviation-delete-modal', defaultInjectValue)

    const formModals = {
      deviationVehicle: inject<
        UseModal<Record<string, unknown>> | DefaultInjectValue
      >('deviation-vehicle-form-modal', defaultInjectValue),
      deviationDelay: inject<
        UseModal<Record<string, unknown>> | DefaultInjectValue
      >('deviation-delay-form-modal', defaultInjectValue),
      deviationCancel: inject<
        UseModal<Record<string, unknown>> | DefaultInjectValue
      >('deviation-cancel-form-modal', defaultInjectValue),
      deviationCrew: inject<
        UseModal<Record<string, unknown>> | DefaultInjectValue
      >('deviation-crew-form-modal', defaultInjectValue),
      deviationDisruption: inject<
        UseModal<Record<string, unknown>> | DefaultInjectValue
      >('deviation-disruption-form-modal', defaultInjectValue),
    }

    const deviationHideModal = inject<
      UseModal<Record<string, unknown>> | DefaultInjectValue
    >(`deviation-${props.deviationProposalType}-hide-modal`, defaultInjectValue)

    const actionEdit = (row: Record<string, unknown>) => {
      formModals[props.deviationType]?.openModal({
        mode: 'update',
        data: row,
      })
    }

    const createDeviationFromProposal = async (
      row: Record<string, unknown>
    ) => {
      if (!props.event) return
      const searchParam = {
        tnr: row.advertised as number,
        date: format(new Date(row.departureDate as string), 'yyyy-MM-dd'),
      }
      let searchTrainDirectData = await searchDirect(searchParam)

      if (typeof searchTrainDirectData === 'number') {
        searchTrainDirectData = await searchDirect({
          tnr: searchTrainDirectData,
          date: searchParam.date,
        })
      }

      const { data: vehicleCompositionData } = await searchTrainComposition({
        tnr:
          typeof searchTrainDirectData === 'number'
            ? searchTrainDirectData
            : searchParam.tnr,
        date: searchParam.date,
      })

      const vehicleComposition = {
        actualCompositions: vehicleCompositionData.actualCompositions,
        expectedCompositions: vehicleCompositionData.expectedCompositions,
      }
      const getPlacesArrival = () => {
        if (
          searchTrainDirectData === null ||
          typeof searchTrainDirectData === 'number'
        )
          return []

        const ank = searchTrainDirectData.places
          .filter((place) => place.activityType === 'ank')
          .slice()
          .map((x) => Object.assign({}, x))
        const sortedAnk = Object.values(
          orderBy(ank, ['advertisedTimeAtLocation'], ['asc']).reduce<{
            [key: string]: SearchTrainPlace
          }>((acc, place) => {
            if (!acc[`${place.location}-${place.advertisedTimeAtLocation}`]) {
              acc[`${place.location}-${place.advertisedTimeAtLocation}`] = place
            }

            return acc
          }, {})
        )

        return sortedAnk
      }

      const getPlacesDeparture = () => {
        if (!searchTrainDirectData || typeof searchTrainDirectData === 'number')
          return []
        const avg = searchTrainDirectData.places
          .filter((place) => place.activityType === 'avg')
          .slice()
          .map((x) => Object.assign({}, x))

        const sortedAvg = Object.values(
          orderBy(avg, ['advertisedTimeAtLocation'], ['asc']).reduce<{
            [key: string]: SearchTrainPlace
          }>((acc, place) => {
            if (!acc[`${place.location}-${place.advertisedTimeAtLocation}`]) {
              acc[`${place.location}-${place.advertisedTimeAtLocation}`] = place
            }

            return acc
          }, {})
        )

        return sortedAvg
      }

      const getDeparture = (value: string) => {
        const placesDeparture = getPlacesDeparture()
        if (!placesDeparture.length) return new Date()
        const placeDeparture = placesDeparture.find(
          (place) => place.location === value
        )
        if (!placeDeparture) return new Date()
        return new Date(placeDeparture.advertisedTimeAtLocation)
      }

      const getArrival = (value: string) => {
        const placesArrival = getPlacesArrival()
        if (!placesArrival.length) return new Date()
        const placeArrival = placesArrival.find(
          (place) => place.location === value
        )
        if (!placeArrival) return new Date()
        return new Date(placeArrival.advertisedTimeAtLocation)
      }

      const getVehicleTypes = () => {
        function findVehicleTypeUuid(name: string | undefined) {
          if (!name) return null

          return vehicleTypes.value.find((x) => x.name === name)?.uuid || null
        }

        return {
          plannedVehicleType1: findVehicleTypeUuid(
            vehicleComposition.expectedCompositions[0]
          ),
          plannedVehicleType2: findVehicleTypeUuid(
            vehicleComposition.expectedCompositions[1]
          ),
          plannedVehicleType3: findVehicleTypeUuid(
            vehicleComposition.expectedCompositions[2]
          ),
          newVehicleType1: findVehicleTypeUuid(
            vehicleComposition.actualCompositions[0]
          ),
          newVehicleType2: findVehicleTypeUuid(
            vehicleComposition.actualCompositions[1]
          ),
          newVehicleType3: findVehicleTypeUuid(
            vehicleComposition.actualCompositions[2]
          ),
        }
      }

      if (props.deviationType === 'deviationCancel') {
        const createModel = () => ({
          uuid: uuidv4(),
          departureDate: row.departureDate,
          departure: getDeparture(row.from as string),
          arrival: getArrival(row.to as string),
          advertised: row.advertised,
          from: row.from,
          to: row.to,
          description: '',
          eventUuid: props.event?.uuid || '',
          ...getVehicleTypes(),
          eventName: null,
          eventId: null,
          eventIsOpen: null,
          eventDate: null,
        })

        await addDeviationCancel(createModel() as DeviationCancel)
      }

      if (props.deviationType === 'deviationVehicle') {
        const createModel = () => ({
          uuid: uuidv4(),
          departureDate: row.departureDate,
          departure: getDeparture(row.from as string),
          arrival: getArrival(row.to as string),
          advertised: row.advertised,
          from: row.from,
          to: row.to,
          vehicleChange: row.vehicleChange,
          description: '',
          eventUuid: props.event?.uuid || '',
          ...getVehicleTypes(),
          eventName: null,
          eventId: null,
          eventIsOpen: null,
          eventDate: null,
        })

        const model = createModel() as Partial<DeviationVehicle>

        if (!projectDependentInputFields.value.vehicleChange) {
          delete model.vehicleChange
        }

        if (!projectDependentInputFields.value.vehicleType) {
          delete model.newVehicleType1
          delete model.newVehicleType2
          delete model.newVehicleType3

          delete model.plannedVehicleType1
          delete model.plannedVehicleType2
          delete model.plannedVehicleType3
        }
        await addDeviationVehicle(model as DeviationVehicle)
      }

      if (props.deviationType === 'deviationDelay') {
        const createModel = () => ({
          uuid: uuidv4(),
          bana: row.bana,
          departureDate: row.departureDate,
          delayMinutes: row.delayMinutes,
          delayLocation: row.delayLocation,
          delayReason: row.delayReason,
          delayReasonDescription: row.delayReasonDescription,
          delayAdvertisedTime: new Date(row.delayPlannedTime as string),
          delayType: row.delayType,
          advertised: row.advertised,
          description: '',
          eventUuid: props.event?.uuid || '',
          eventName: null,
          eventId: null,
          eventIsOpen: null,
          eventDate: null,
        })
        await addDeviationDelay(createModel() as DeviationDelayModel)
      }
    }

    const removeProposal = async (m: unknown) => {
      if (!props.event) return

      if (props.deviationType === 'deviationVehicle') {
        const model = Object.assign({}, m) as DeviationVehicle
        deviationVehicleProposals.value =
          deviationVehicleProposals.value.filter(
            (x) =>
              !(
                x.advertised === model.advertised &&
                format(new Date(x.departureDate), 'yyyy-MM-dd') ===
                  (model.departureDate
                    ? format(new Date(model.departureDate), 'yyyy-MM-dd')
                    : '') &&
                x.from === model.from &&
                x.to === model.to &&
                x.vehicleChange === model.vehicleChange
              )
          )
      }

      if (props.deviationType === 'deviationDelay') {
        const model = m as DeviationDelay
        deviationDelayProposals.value = deviationDelayProposals.value.filter(
          (x) =>
            !(
              x.advertised === model.advertised &&
              format(new Date(x.departureDate), 'yyyy-MM-dd') ===
                (model.departureDate
                  ? format(new Date(model.departureDate), 'yyyy-MM-dd')
                  : '') &&
              x.delayType === model.delayType &&
              x.delayMinutes === model.delayMinutes &&
              x.delayLocation === model.delayLocation &&
              format(new Date(x.delayAdvertisedTime), 'yyyy-MM-dd HH:mm') ===
                (model.delayAdvertisedTime
                  ? format(
                      new Date(model.delayAdvertisedTime),
                      'yyyy-MM-dd HH:mm'
                    )
                  : '')
            )
        )
      }

      if (props.deviationType === 'deviationCancel') {
        const model = m as DeviationCancel
        deviationCancelProposals.value = deviationCancelProposals.value.filter(
          (x) =>
            !(
              x.advertised === model.advertised &&
              format(new Date(x.departureDate), 'yyyy-MM-dd') ===
                (model.departureDate
                  ? format(new Date(model.departureDate), 'yyyy-MM-dd')
                  : '') &&
              x.from === model.from &&
              x.to === model.to
            )
        )
      }
    }

    function actionItems(row: { uuid?: string }) {
      return [
        {
          icon: 'mdi-pencil',
          label: 'Lägg till förslag direkt',
          visible: !row.uuid && props.event,
          permission: `deviationManagement.${props.deviationType}.create`,
          action: async (row: Record<string, unknown>) => {
            await createDeviationFromProposal(row)
            await removeProposal(row)
          },
        },
        {
          icon: 'mdi-pencil',
          label: 'Skapa förslag',
          visible: !row.uuid,
          permission: `deviationManagement.${props.deviationType}.create`,
          action: (row: Record<string, unknown>) => {
            formModals[props.deviationType]?.openModal({
              mode: 'create-proposal',
              data: row,
            })
          },
        },
        {
          icon: 'mdi-eye-off-outline',
          label: 'Dölj förslag',
          visible: !row.uuid && !props.event,
          permission: `deviationManagement.${props.deviationType}Proposal.delete`,
          action: (row: Record<string, unknown>) => {
            deviationHideModal.openModal({
              data: row,
            })
          },
        },
        {
          icon: 'mdi-pencil',
          label: 'Redigera',
          visible: row.uuid,
          permission: `deviationManagement.${props.deviationType}.update`,
          action: actionEdit,
        },
        {
          icon: 'mdi-delete',
          label: 'Ta bort',
          visible: row.uuid,
          permission: `deviationManagement.${props.deviationType}.delete`,
          action: (row: Record<string, unknown>) => {
            deleteModal?.openModal({
              mode: props.deviationType,
              data: row,
            })
          },
        },
        {
          icon: 'mdi-view-list',
          label: 'Logg',
          visible: row.uuid,
          permission: `logging.log.list`,
          action: (row: Record<string, unknown>) => {
            logModal?.openModal({
              mode: props.deviationType,
              cb: async (setData) => {
                if (!row.uuid) return Promise.resolve()
                const { data } = await getLog(row.uuid as string)
                setData(data)
                return Promise.resolve()
              },
            })
          },
        },
      ].filter((actionItem) => can(actionItem.permission) && actionItem.visible)
    }

    const columnsWithActions = computed(() => {
      const col = []
      if (props.event) {
        col.push({
          label: '',
          field: '_select',
          required: true,
          align: 'left',
          name: '_select',
          sortable: false,
        })
      }
      return [
        {
          label: '',
          field: '_action',
          required: true,
          align: 'right ',
          name: '_action',
          sortable: false,
          style: 'width: 78px; min-width: 78px; max-width: 78px',
        },
        ...col,
        ...props.columns,
      ] as QTable['columns']
    })

    async function onExport() {
      exporting.value = true
      exportExcel(props.exportData)
      exporting.value = false
    }

    function getColumnName(
      col: typeof QTable['columns'][0],
      props: { row: { [key: string]: unknown } }
    ) {
      const value =
        typeof col.field === 'function'
          ? col.field(props.row)
          : props.row[col.field]
      return col.format ? col.format(value) : value
    }

    async function onAddSelectedProposals() {
      createDeviationFromProposalLoading.value = true
      await Promise.all(selectedItems.value.map(createDeviationFromProposal))
      selectedItems.value.forEach(removeProposal)
      createDeviationFromProposalLoading.value = false
      selectedItems.value = []
    }

    function hasAutoDelay(row: { eventFlags?: { flag: string }[] }) {
      if (
        row.eventFlags?.some((x) => {
          return x.flag === 'AUTO_DELAY'
        })
      ) {
        return true
      }
      return false
    }

    function onClickMpkChip(mpk_event_id: string) {
      const eventId = mpk_event_id.split(' ')[0].replace('H', '')
      mpkModal?.openModal({
        cb: async (setData) => {
          await getMpkEvent(eventId)
            .then(({ data }) => {
              setData({
                eventId,
                mpkData: data,
              })
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    function onClickOpenDelay(row: DeviationDelay) {
      delayModal?.openModal({
        cb: async (setData) => {
          const params = {
            technical: row.advertised as number,
            departureDate: format(new Date(row.departureDate), 'yyyy-MM-dd'),
          }
          await Promise.all([
            searchDelay(params),
            searchPrevTripFromTraind({
              tnr: params.technical,
              date: params.departureDate,
            }),
          ])
            .then(([{ data }, { data: prevTripFromTraind }]) => {
              setData({
                data,
                prevTripFromTraind,
              })
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    const toggleAllSelect = ref<boolean | null>(false)

    const onToggleAllSelect = (v: boolean) => {
      if (!tableRef.value) return
      const proposals = tableRef.value.filteredSortedRows.filter((x) => !x.uuid)

      if (v) {
        selectedItems.value = proposals
      } else {
        selectedItems.value = []
      }
      toggleAllSelect.value = v
    }

    watch(
      () => selectedItems.value,
      (v) => {
        const proposals = (props.rows || []).filter((x) => !x.uuid)
        if (v.length && v.length !== proposals?.length) {
          toggleAllSelect.value = null
          return
        }

        if (!v.length) {
          toggleAllSelect.value = false
          return
        }

        if (v.length === proposals?.length) {
          toggleAllSelect.value = true
          return
        }
      }
    )

    const handleAssignTrvToEvent = async (id: string) => {
      const match = id.match(/\d+/)
      if (!match || !match[0]) return

      const res = await updateEvent({
        ...props.event,
        eventTrvs: [
          ...(props.event?.eventTrvs || []),
          { eventUuid: props.event?.uuid as string, trvId: match[0] },
        ],
      })
      if (!res) return
      emit('updateTrvId')
    }

    return {
      exporting,
      onExport,
      actionItems,
      columnsWithActions,
      getColumnName,
      filterText: ref(''),
      logModal,
      actionEdit,
      selectedItems,
      onAddSelectedProposals,
      createDeviationFromProposalLoading,
      routerPush,
      onClickMpkChip,
      mpkModal,
      hasAutoDelay,
      onClickOpenDelay,
      delayModal,
      toggleAllSelect,
      onToggleAllSelect,
      tableRef,
      handleAssignTrvToEvent,
    }
  },
})
